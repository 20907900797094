body {
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fade-in {
  opacity: 0;
  transition: opacity 1s ease-in;
}

.fade-in.show {
  opacity: 1;
}

.grayhover:hover {
  color: lightgray !important;
}
